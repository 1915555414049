import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faPhone, faCaretDown, faBars, faArrowCircleRight, faTimesCircle, faPrint, faThumbsUp, faTrashAlt     } from '@fortawesome/pro-solid-svg-icons'
import { faUser as faUserReg, faPhone as faPhoneReg, faCaretDown as faCaretDownReg, faBars as faBarsReg, faArrowCircleRight as faArrowCircleRightReg, faTimesCircle as faTimesCircleReg, faPrint as faPrintReg, faThumbsUp as faThumbsUpReg, faTrashAlt as faTrashAltReg } from '@fortawesome/pro-regular-svg-icons'
// import { } from '@fortawesome/pro-light-svg-icons'
import { faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUser, faUserReg, faFacebook, faInstagram, faPhone, faPhoneReg, faCaretDown, faCaretDownReg, faBars, faBarsReg, faArrowCircleRight, faArrowCircleRightReg, faTimesCircle, faTimesCircleReg, faPrint, faPrintReg, faThumbsUp, faThumbsUpReg, faTrashAlt, faTrashAltReg )

require('@fortawesome/fontawesome-pro/css/all.css');
// require('@fortawesome/fontawesome-pro/js/all.js');


Vue.component('fai', FontAwesomeIcon)