
'use strict';


class EventEmitter{

    constructor(){
        this._listener = {};
    }

    on(event, callback){
        if(this._listener[event]){
            this._listener[event].push(callback);
        }else{
            this._listener[event] = [callback];
        }
    }

    listenerCount(event){
        return this._listener[event] ? this._listener[event].length:0
    }

    off(callback, event){
        if(event){
            if(this._listener[event] && this._listener[event].indexOf(callback) !== -1){
                this._listener[event].splice(this._listener[event].indexOf(callback), 1);
            }
        }else{
            for(let i in this._listener){
                if(this._listener[i].indexOf(callback) !== -1){
                    this._listener[i].splice(this._listener[i].indexOf(callback), 1);
                }
            }
        }
    }

    once(event, callback){

        let _this = this;
        let _wrapper = function(){     

            _this.off(this, event);
            callback.apply(_this, arguments);

        }

        _this.on(event, _wrapper);
    }

    emit(){  

        if(arguments.length < 1){
            return;
        }

        let args = Array.prototype.slice.call(arguments);
        let eventName = args.shift();

        if(this._listener[eventName] && this._listener[eventName].length > 0){                        
            let _this = this;
            this._listener[eventName].forEach(function(callback){
                if(typeof callback === 'function'){
                    (function(callback){
                        callback.apply(_this, args);
                    })(callback);
                }                
            });

        }

    }
}

export default EventEmitter;