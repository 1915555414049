import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: '#b71f2e',
          secondary: '#d9d0be',
          accent: '#ae906c',
          error: '#b71f2e',
          info: '#d6d6d6',
          success: '#4CAF50',
          warning: '#FFC107',
          usbg:'#ffffff',

        },
    },
  },
    lang: {
      locales: { de },
      current: 'de',
    },
  icons: {
    iconfont: 'fa',
  },
});
