import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'highlights',
        component: require('@/components/Highlights').default
    },
    {
        path: '/plain',
        name: 'plain',
        component: require('@/components/Plain').default
    },
    {
        path: '/merkliste',
        name: 'merkliste',
        component: require('@/components/Merkliste').default
    },
    {
        path: '/reiseuebersicht/:reiseuebersicht?/:search?',
        name: 'reiseuebersicht',
        component: () => import('@/components/Reiseuebersicht.vue')
    },
    {
        path: '/forms/:forms?',
        name: 'forms',
        component: () => import('@/components/Forms.vue')
    },
    {
        path: '/reise/:reise?/:reisetitel?',
        name: 'reise',
        component: () => import('@/components/Reise.vue')
    },
    {
        path: '/newsletter',
        name: 'newsletter',
        component: () => import('@/components/Newsletter.vue')
    },
    {
        path: '/article/:article?',
        name: 'article',
        component: () => import('@/components/Article.vue')
    },

]

const router = new VueRouter({
    routes
})

export default router