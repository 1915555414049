<template>

  <div class="d-center-content fill-height" :class="{'mr-7': $vuetify.breakpoint.smAndDown}">

    <div class="d-skew-mother">
      <div class="d-skew-left px-10">Aktuelle Reiseangebote!</div>
      <!-- <div class="d-skew-left px-10">Highlights auf einem Blick</div> -->
      <div class="d-skew-center"></div>
      <div class="d-skew-right"></div>
      <div class="d-skew-abdecker "></div>
      <div class="d-skew-abdecker-white "></div>
    </div>
    <div class="d-content-content">

      <!-- <v-container fluid class="pl-0 pr-10">
        <span class="font-weight-bold">Liebe Reisegäste,</span> <br>
        nach der zurückliegenden Durststrecke auf Grund des Busreiseverbots freuen wir uns, dass wir ab Juli
        wieder gemeinsam „Fahrt aufnehmen“ dürfen. Aus diesem Anlass haben wir neben den bekannten Reisen aus unserem Katalog
        einige neue Ziele ins Programm aufgenommen.
        <br><br>
        Im Rahmen der Verordnung zur Eindämmung der Corona-Pandemie in Thüringen vom 01.06.2021 sind
        Auflagen zur Durchführung von Busreisen festgelegt wurden.
        Hierbei ist für Sie als Kunde folgendes zu beachten:<br>
        » Vor Antritt der Reise ist ein Nachweis über die Impfung gegen das Corona-Virus (Impfpass) oder eine
        Bescheinigung über die Genesung (Antigen-Test/Attest) oder ein tagesaktueller, negativer Corona-
        Schnelltest vorzulegen.<br>
        » Diese Pflicht entfällt bei einer 7-Tages-Inzidenz von unter 35 im Kreis Eichsfeld (Firmensitz).<br>
        » Beim Ein- und Aussteigen sowie während der Fahrt muss der Mund-/Nasenschutz getragen werden
        <br><br>
        Zu Ihrer Sicherheit haben wir darüber hinaus für alle Reisen ein Hygienekonzept entwickelt.
        Durch die sich ständig ändernden Auflagen und Bedingungen kann es u. U. zu kleinen Abweichungen vom
        geplanten Reiseprogramm kommen. Wir bitten um Besonnenheit und Verständnis in so einer für uns alle
        außergewöhnlichen Lage.
        <br><br> -->
        <!-- Für Fragen und Buchungswünsche stehen wir Ihnen selbstverständlich wie gewohnt telefonisch und
        persönlich in unserem Büro gerne zur Verfügung.
        <br><br>
        <span class="font-weight-bold">Wir freuen uns auf Sie! Ihre Familie Döring</span>
        <br><br>
       </v-container> -->


       <v-card class="d-inline-block mr-5 mb-5 d-cards-hightlight" :class="{'d-cards-hightlight-small': $vuetify.breakpoint.sm}" v-for="(item,a) in Jcarts" :key="a" style="cursor:pointer;" @click="navigateJourney(item)">
        <v-img class="" height="200px" :src="item.img.link">
          <v-card-title class="justify-end pa-0">
            <div style="background:rgba(255,255,255,0.6)" class="px-2 float-right">
              <span class="body-2 pt-1">ab</span>
              <span class="primary--text pl-1 title font-weight-bold"> {{ new Intl.NumberFormat('de-DE').format(item.price) }} ,- € </span>
              <!-- <span class="primary--text pl-1 title font-weight-bold"> {{ item.price }} ,- €</span> -->
            </div>
          </v-card-title>
        </v-img>
          <v-card-text class="primary usbg--text" style="height:90px;overflow:hidden" :class="{'d-cards-down': $vuetify.breakpoint.sm}">
            <div class="body-2">{{item.duration}} Tage</div>
            <div class="font-weight-bold" >{{item.title}}</div>
          </v-card-text>
        </v-card>
        <!-- <pre>{{JourneyCategoryStore}}</pre> -->
         <!-- <pre>{{Jcarts}}</pre>  -->

    </div>
    <!-- <iframe class="mj-w-res-iframe ml-n4" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://x1out.mjt.lu/wgt/x1out/4i1/form?c=78725875" width="100%" height="550"></iframe> -->
    <MailJetNL ref="MailJetNL" Nltype="plain"></MailJetNL>
  </div>
</template>

<script>
  import { mapState } from "vuex"
  import MailJetNL from "@/components/Newsletter/mailjet.vue"
  export default {
    computed: {
        ...mapState(["ContentStore", "JourneyStore", "JourneyCategoryStore"]),
    },

    data() {
        return {
          Jcarts: [],
          tempJourney:[]
        }
      },
      components: {
          MailJetNL
      },
      methods: {
        navigateJourney(jrn) {
              core.navigate({ path: "/reise/" + jrn.id_jrn_journey + "/" + jrn.title.replace(/ /g,'-').replace(/[^\w-]+/g,'') })
        }

      },
      mounted() {
          this.args = core.getPathArgs(["category", "search"])
          try {
              this.journeyCategory = this.JourneyCategoryStore.find(cat => cat.name == decodeURIComponent(this.args.category)).id_jrn_category
          } catch (error) {
              this.journeyCategory = ""
          }

          let args = {
              id_acc_account: core.id_acc_account,
              category: 56,

          }

          core.backend("jrn.listJourneysWeb", args, (err, res) => {
              if (err) {
                  return
              }
              res.data.rows.forEach(jrney => {
                  jrney.dates.forEach((date, dateKey) => {
                      let _newJourney = JSON.parse(JSON.stringify(jrney))
                      _newJourney.dates = [date]
                      if (date.duration === 1) {
                        console.log("Ick wurd ausgeführt")
                        return
                      }
                      if (date.price === 0) {
                          return
                      }
                      if (this.Jcarts.length === 6) {
                        return
                      }
                      if (dateKey > 0) {
                        return
                      }
                      // if(this.dates.date_from === 1) {
                      //   return
                      // }
                      this.Jcarts.push({
                          title: _newJourney.title,
                          date_from: date.date_from,
                          date_till: date.date_till,
                          einleitung: _newJourney.einleitung,
                          reisebeschreibung: _newJourney.reisebeschreibung,
                          duration: date.duration,
                          price: (date.price/1000000),
                          id_jrn_journey: _newJourney.id_jrn_journey,
                          id_prj_project: _newJourney.id_prj_project,
                          img: _newJourney
                      })
                      this.tempJourney.push({
                          id:_newJourney.id_jrn_journey,
                          title: _newJourney.title,
                          path: "/reise/" + _newJourney.id_jrn_journey + "/" + _newJourney.title.replace(/ /g,'-').replace(/[^\w-]+/g,'')
                      })
                      this.$store.commit('setJourneyPanz', this.tempJourney);
                  })
              })
              this.Jcarts = this.Jcarts.sort((a, b) => a.title > b.title)
          })


      },
    };
</script>

<style lang="scss">

  .d-cards-hightlight{
    width:280px;
  }
  .d-cards-hightlight-small{
    width:240px;
  }
  .d-cards-hightlight div, .d-cards-hightlight-small div {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}



</style>
