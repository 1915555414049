<template>
    <div>
        <div v-if="Nltype=='popup'">
            <v-overlay :value="dialogNL1">
                <v-card class="white black--text" width="320" style="max-height: 90vh; overflow: auto;">
                    <div>
                        <v-card-title>Wir brauchen ihre Einwilligung</v-card-title>
                        <v-card-text >
                            Wenn Sie den Newsletter Abonnieren wollen müssen Sie ihre Zustimmung in form eines Cookies speichern. Ihre Zustimmung ist Bestandteil der DSGVO.
                            <br>
                            <a class="error--text" @click="openccm19Tool()" >Konfigurationstool öffnen</a>
                        </v-card-text>
                    </div>
                    <v-card-actions class="pt-0 pb-3 d-flex align-center justify-center">
                        <v-btn color="primary" x-small @click="dialogNL1 = false" height="auto" class="py-1">
                            <div class="d-flex flex-column">
                                <span>Abrechen /</span> <span>Fenster schließen</span>
                            </div>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-overlay>
            <v-overlay :value="dialogNL2">
                <v-card class="white" style="max-height: 90vh; overflow: auto;">
                    <div style="position:relative;">
                        <div class="d-flex justify-end black--text" style="z-index: 9999;position:absolute;top:2px;right:2px;">
                            <fai :icon="['far', 'times']" class="ma-2 mb-n3 text-h5" @click="dialogNL2 = false" style="cursor: pointer;"></fai>
                        </div>
                        <iframe data-w-type="embedded" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0" :src="cookiesrc" width="100%"></iframe>
                    </div>
                    <v-card-actions class="pt-0 pb-3 d-flex align-center justify-center">
                        <v-btn color="primary" x-small @click="dialogNL2 = false" height="auto" class="py-1" style="width: 90%; max-width: 250px;">
                            <div class="d-flex flex-column">
                                <span>Abrechen /</span> <span>Fenster schließen</span>
                            </div>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-overlay>
        </div>
        <v-container fluid fill-height v-if="Nltype=='plain'" class="d-flex align-center justify-center">
            <div v-if="dialogNL1==true" >
                <v-card class="white black--text" width="320">
                    <div>
                        <v-card-title>Wir brauchen ihre Einwilligung</v-card-title>
                        <v-card-text >
                            Wenn Sie den Newsletter Abonnieren wollen müssen Sie ihre Zustimmung in form eines Cookies speichern. Ihre Zustimmung ist Bestandteil der DSGVO.
                            <br>
                            <a class="error--text" @click="openccm19Tool()" >Konfigurationstool öffnen</a>
                        </v-card-text>
                    </div>
                    <v-card-actions class="pt-0 pb-3 d-flex align-center justify-center">
                        <v-btn color="primary" x-small @click="dialogNL1 = false" height="auto" class="py-1">
                            <div class="d-flex flex-column">
                                <span>Abrechen /</span> <span>Fenster schließen</span>
                            </div>
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </div>
            <iframe class="mj-w-res-iframe" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0" :src="cookiesrc" width="100%"></iframe>
        </v-container>

    </div>
</template>

<script>
    export default {
        props: {
            Nltype: {
                type: String,
                default: '',
            }
        },

        data() {
            return {
                dialogNL1:false,
                dialogNL2:false,
                mailjetCookies:null,
                cookiesrc:null,
            };
        },
        head: {
            script: [{ src: "./mailjet.js" }]
        },
        watch: {
            '$data.mailjetCookies': {
            handler: function(newValue) {
                if(!CCM.acceptedCookies.includes("mail_session")){
                    this.cookiesrc='';
                    this.dialogNL2 = false;
                    if (this.Nltype == 'plain') {this.dialogNL1 = true;}
                }
                else {
                    this.dialogNL2 = true;
                    if (this.Nltype == 'plain') {this.dialogNL1 = false;}
                    this.$nextTick(() => {
                        this.cookiesrc='https://x1out.mjt.lu/wgt/x1out/4i1/form?c=78725875';
                    });
                }
            },
            deep: true
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.mailjetCookies = CCM.acceptedCookies;
                window.addEventListener("ccm19WidgetClosed", this.ccm19WidgetClosed)
            });
        },
        destroyed() {
            window.removeEventListener( "ccm19WidgetClosed", this.ccm19WidgetClosed );
        },
        methods: {
            ccm19WidgetClosed(){
                this.mailjetCookies = CCM.acceptedCookies;
            },
            checkMailjet() {
                if(!CCM.acceptedCookies.includes("mail_session")){
                    this.dialogNL1 = true;
                }
                else {
                    this.dialogNL2 = true;
                    this.$nextTick(() => {
                        this.cookiesrc='https://x1out.mjt.lu/wgt/x1out/4i1/form?c=78725875';
                    });
                }
            },
            openccm19Tool() {

                this.dialogNL1 = false;
                CCM.openWidget();

            }
        }
    };
</script>

<style lang="scss" scoped>

@media (min-width: 1054px) {
    .mj-w-res-iframe {
        height: 484px;
    }
}

@media (max-width: 1054px) and (min-width: 731px) {
    .mj-w-res-iframe {
        height: 565px;
    }
}

@media (max-width: 731px) and (min-width: 653px) {
    .mj-w-res-iframe {
        height: 535px;
    }
}

@media (max-width: 653px) and (min-width: 599px) {
    .mj-w-res-iframe {
        height: 715px;
    }
}

@media (max-width: 599px) and (min-width: 450px) {
    .mj-w-res-iframe {
        height: 814px;
    }
}

@media (max-width: 450px) {
    .mj-w-res-iframe {
        height: 840px;
    }
}
</style>