let __callback = function(callback, err, resData, reqId) {
    if (callback !== undefined && typeof callback === 'function') {
        if (!err && resData) {
            if (resData.error) {
                err = new Error(resData.error);
                console.log("Error", resData.error)
            } else if (resData.denied) {
                err = new Error("Zugriff verweigert");
            }
        }


        callback(err, resData, reqId);
    }
};


let __execute = function(_id, endpoint, cmd, data, callback) {

    let reqData = data || {};
    reqData._reqId = _id;

    fetch(endpoint + '/' + cmd, {
            method: "POST",
            body: JSON.stringify(reqData)
        })
        .then(res => res.json())
        .then(jsonData => {
            __callback(callback, null, jsonData ? jsonData : {}, _id);
        })
        .catch(error => {
            __callback(callback, error, null, _id);
        });
};

let _globalReqId = 0;

class Com {

    constructor(endpoint) {
        this.endpoint = endpoint;

        if (!this.endpoint.endsWith("/")) {
            this.endpoint += "/"
        }

        this._reqId = 0;
    }

    send(cmd, args, callback, delay) {

        _globalReqId++;
        ++this._reqId;

        let _id = this._reqId;
        let _this = this;

        if (delay === undefined || isNaN(parseInt(delay)) || parseInt(delay) === 0) {
            __execute(_id, _this.endpoint, cmd, args, callback);
        } else {

            //warten ob weitere Anfragen kommen
            setTimeout(function() {

                //Verwerfen falls obsolet
                if (_id === _this._reqId) {
                    __execute(_id, _this.endpoint, cmd, args, callback, delay);
                }

            }, delay);
        }

        return _id;
    }

    download(cmd, path) {
        window.location = this.endpoint + "?cmd=" + cmd + "&path=" + encodeURIComponent(path);
    }
}


export default Com;