<template>
  
  <div class="d-center-content fill-height" :class="{'mr-7': $vuetify.breakpoint.smAndDown}">
                    
    <div class="d-skew-mother">
      <div class="d-skew-left px-10">Merkliste</div>
      <div class="d-skew-center"></div>
      <div class="d-skew-right"></div>
      <div class="d-skew-abdecker "></div>
      <div class="d-skew-abdecker-white "></div>   
    
      <div style="position:absolute;right:0px;color:#676767; cursor:pointer;" @click.stop="delMerk()" :class="{'pr-3': $vuetify.breakpoint.smAndDown}" >Merkliste löschen <fai :icon="['fas', 'trash-alt']" ></fai></div>
    </div>
    <div class="d-content-content">       
       
       <!-- <pre> {{Jcarts}} </pre> -->
          <div v-if="Jcarts.length > 0">
            <v-card v-if="$vuetify.breakpoint.mdAndUp" class="d-cards-reiseuebersicht mb-5" elevation="6" v-for="(item,a) in Jcarts" :key="a" style="cursor:pointer;" @click="navigateJourney(item)" :class="{'d-none': $vuetify.breakpoint.smAndDown}">
                <v-img v-if="item.img.link" class="" :src="item.img.link"  height="180" width="250">
                  <div class="pa-2" style="background-color:rgba(255,255,255,0.5);width:35px;height:38px;" @click.stop="delThisMerk(a)"><fai style="font-size:22px;" :icon="['fas', 'trash-alt']" ></fai></div>
                </v-img>
                <v-img v-else  height="180" width="250" :src="notPic">
                   <div class="pa-2" style="background-color:rgba(255,255,255,0.5);width:35px;height:38px;" @click.stop="delThisMerk(a)"><fai style="font-size:22px;" :icon="['fas', 'trash-alt']" ></fai></div>
                </v-img>            
                <v-card-text style="position:absolute;top:0px;right:0px;width:calc(100% - 250px);height:inherit;" class="px-5 py-2">
                  <div class="subtitle-2 font-weight-bold" style="width:calc(100% - 40px);">{{item.title}}</div>          
                  <div class="caption">{{item.img.subtitle}}</div>
                  <div class="caption pt-2 pr-5" :class="{'d-none': $vuetify.breakpoint.mdAndDown}" style="line-height:120%;">{{item.einleitung}}</div>
                  <div class="mb-5" style="position:absolute;width:calc(100% - 70px); bottom:-10px;">
                    <div style="float:left;" class="pt-2 font-weight-bold">{{settheDate(item.date_from)}} - {{settheDate(item.date_till)}}</div>             
                    <div class="primary--text pl-1 title font-weight-bold" style="float:right"><span class="caption">ab</span> {{ new Intl.NumberFormat('de-DE').format(item.price) }} ,- €</div>
                  </div>
                  <div class="primary" style="position:absolute;height:100%;width:30px; right:0px;top:0px;">
                    <fai class="usbg--text ml-1" :icon="['fas', 'arrow-circle-right']" style="position:absolute;font-size:22px;top:calc(50% - 11px);" ></fai>
                  </div>            
                </v-card-text>
              </v-card>   

              <v-card v-if="$vuetify.breakpoint.smAndDown" class="mr-5 mb-5 reiseuebersicht-small"  v-for="(item,b) in Jcarts" :key="b" style="cursor:pointer;" @click="navigateJourney(item)" :class="{'d-inline-block': $vuetify.breakpoint.smAndDown}">
                <v-img v-if="item.img.link" class="" height="200px" :src="item.img.link" style="position:relative;">
                  <div class="pa-2" style="position:absolute;left:0px;background-color:rgba(255,255,255,0.5);width:35px;height:38px;" @click.stop="delThisMerk(b)"><fai style="font-size:22px;" :icon="['fas', 'trash-alt']" ></fai></div>
     
                  <v-card-title class="justify-end pa-0">
                    <div style="background:rgba(255,255,255,0.6" class="px-2 float-right">
                      <span class="body-2 pt-1">ab</span> 
                      <span class="primary--text pl-1 title font-weight-bold"> {{ new Intl.NumberFormat('de-DE').format(item.price) }} ,- €</span> 
                    </div>
                  </v-card-title>
                </v-img>
                <v-img v-else class="" height="200px" :src="notPic" style="position:relative;">
                  <div class="pa-2" style="position:absolute;left:0px;background-color:rgba(255,255,255,0.5);width:35px;height:38px;" @click.stop="delThisMerk(b)"><fai style="font-size:22px;" :icon="['fas', 'trash-alt']" ></fai></div>
     
                  <v-card-title class="justify-end pa-0">
                    <div style="background:rgba(255,255,255,0.6" class="px-2 float-right">
                      <span class="body-2 pt-1">ab</span> 
                      <span class="primary--text pl-1 title font-weight-bold"> {{ new Intl.NumberFormat('de-DE').format(item.price) }} ,- €</span>
                    </div>
                  </v-card-title>
                </v-img>
                <v-card-text class="" style="height:90px;overflow:hidden" :class="{'d-cards-down': $vuetify.breakpoint.sm}">
                  <div class="font-weight-bold">{{item.title}}</div>
                  <div class="body-2">{{settheDate(item.date_from)}} - {{settheDate(item.date_till)}}</div>
                </v-card-text>
            </v-card>
          </div>
          <div v-else>
              <v-card class="d-cards-reiseuebersicht mb-5" elevation="6" :class="{'d-none': $vuetify.breakpoint.smAndDown}">
                <v-img  height="180" width="250" :src="notPic"></v-img>            
                <v-card-text style="position:absolute;top:0px;right:0px;width:calc(100% - 250px);height:inherit;" class="px-10 py-10">
                  <div class="title font-weight-bold primary--text py-8" style="width:calc(100% - 40px);">Keine Reise gefunden</div>
                </v-card-text>
              </v-card>
              <v-card class="mr-5 mb-5 reiseuebersicht-small" :class="{'d-inline-block': $vuetify.breakpoint.smAndDown}">
                <v-img class="" height="200px" :src="notPic" > </v-img>
                <v-card-text class="" style="height:90px;overflow:hidden" :class="{'d-cards-down': $vuetify.breakpoint.sm}">
                  <div class="subtitle-1 font-weight-bold primary--text">Keine Reise gefunden</div>
                </v-card-text>               
              </v-card>
          </div>

    </div>
  </div>
</template>


<script>

  import notPic from "@/assets/images/notPic.jpg" 
  import moment from 'moment'

  export default {
    data() {
        return {  
          Jcarts: [],
          notPic,
          tempJourney:[],         
        }
    },
    mounted() {    
      this.setJourneys();
    },
    methods: {
      delMerk() {
        localStorage.removeItem('merkliste');
        localStorage.setItem("merkliste", "[]")
        this.Jcarts = []; 
      },
      delThisMerk(index) {
          this.Jcarts.splice(index , 1); 
          var array =  JSON.parse(localStorage.getItem("merkliste"));      
          array.splice(index , 1);          
          localStorage.setItem("merkliste", JSON.stringify(array) );
          this.setJourneys();
      },
      navigateJourney(jrn) {
              core.navigate({ path: "/reise/" + jrn.id_jrn_journey + "/" + jrn.title.replace(/ /g,'-').replace(/[^\w-]+/g,'') })
      },
      settheDate(date){ return  moment(String(date)).format('DD.MM.YYYY'); },      
      setJourneys() {                         
            
           if (localStorage.getItem("merkliste") === null)  {return;}
            
            
            this.tempjourney = [];
            this.Jcarts = [];          
            var _newJourney = JSON.parse(localStorage.getItem("merkliste"));                      
            for (var a=0; a<_newJourney.length; a++) {
                
                if (_newJourney[a].images[0]  == undefined) {
                  _newJourney[a].images[0] = {link:notPic};
                }
                
                
                this.Jcarts.push({
                    title: _newJourney[a].detail.title,
                    date_from: _newJourney[a].dates[0].dates.date_from,
                    date_till: _newJourney[a].dates[0].dates.date_till,
                    einleitung: _newJourney[a].detail.einleitung,
                    reisebeschreibung: _newJourney[a].detail.reisebeschreibung,
                    duration:  _newJourney[a].days.length,
                    price: (_newJourney[a].dates[0].dates.prices[0].price/1000000),
                    id_jrn_journey: _newJourney[a].id_jrn_journey,
                    id_prj_project: 1,
                    img: _newJourney[a].images[0]
                }); 
                this.tempJourney.push({
                    id:_newJourney[a].id_jrn_journey,
                    title: _newJourney[a].detail.title,
                    path: "/reise/" + _newJourney[a].id_jrn_journey + "/" + _newJourney[a].detail.title.replace(/ /g,'-').replace(/[^\w-]+/g,'')
                })
                this.$store.commit('setJourneyPanz', this.tempJourney);
            }

            this.Jcarts = this.Jcarts.sort((a, b) => a.date_from > b.date_from)
        
      }   
    },

  };
</script>

<style lang="scss">
  @media (max-width: 599px) {
      .reiseuebersicht-small { width:270px; }

  }
  @media (min-width: 600px) and (max-width: 959px) {
      .reiseuebersicht-small { width:240px; }

  }
  @media (min-width: 960px) and (max-width: 1263px) {
      

  }
  @media (min-width: 1264px) and (max-width: 1903px) {
   

  }
  @media (min-width: 1904px) {
   

  }
  .d-cards-reiseuebersicht{
    width:100%;
    height:180px;
    position:relative;   
  }
   .reiseuebersicht-small { 
      position:relative;
      display:none;
    }
  
</style>
