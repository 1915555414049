export default {
    de: {
        hotelAusstattIcon: {
            bar:'Bar',
            barrierefrei:'Barrierefrei',
            kuranwendungen:'Kur',
            safe:'Safe',
            schwimmbad:'Bad',
            saal:'Saal',
            wellness:'Wellness',
            wlan:'WLAN',
            bus:'Busplatz',
            fahrrad:'Verleih',
            nichtraucher:'Nichtraucher',
            beer:'Biergarten',
            fittnes:'Fittness',
            Animals :'Erlaubt',
        },
        zimmerAusstattIcon: {
            bademantel:'Bademantel',
            barrierefrei:'Barrierefrei',
            dusche:'Dusche',
            föhn:'Föhn',
            klimaanlage:'Klima',            
            kühlschrank:'Kühlschrank',
            safe:'Safe',           
            tv:'TV',
            tel:'Telefon',
            wlan:'WLAN',
            badewanne:'Badewanne',
        },
        kurAusstattIcon: {
            arzt:'Arzt',
            heilbäder:'Heilbäder',
            massagen:'Massagen',
            therapie:'Therapie',
            wärme:'Wärme',            
        },
        selverpflegung: {
            uf:'ÜF',
            ufPlus:'ÜF +',
            hp:'HP',
            hpPuls:'HP +',
            vp:'VP',            
            vpPlus:'VP +',            
            ai:'AI',            
            ail:'AI light',            
        },
        janein: {
            ja:'Ja',
            nein:'Nein',          
        },
        global: {
            test: 'Mit Klick zum Wortwolken Raster',            
            zumartikel:'Zum Artikel',
            close:"Schließen",
            stern:"*",
            home:"Home",
            getIt:'Übernehmen',
            lastStation:'letzte Stationen',
            station1:'Station 1',
            station2:'Station 2',
            station3:'Station 3',
            moreInfo:'Weitere Anmerkungen',
            waswir:'Was wir wissen sollten...',
            more:'Mehr erfahren',
            anmelden: 'Anmelden',
            abmelden: 'Abmelden',            
            konto: 'Mein Konto',
            resetpw: 'Passwort zurücksetzen',
            suchen: 'Suchen',
            abbrechen: 'Abbrechen',
            parken: 'Parken',
            beenden: 'Beenden',
            abschicken: 'Abschicken',
            headFormNeukunde: 'Neukunden Registrierung',
            headPasswordReset: 'Passwort vergessen',
            headFormIbewerbung1: 'Werde', 
            headFormIbewerbung2: '100cyan', 
            headFormIbewerbung3: '(Initiativbewerbung)', 
            headFormNews: 'Newsletter abonnieren', 
            headFormorderKat: 'Katalog / Info anfordern', 
            headFormSearch: 'Reise suchen', 
            headJourneySearch: 'Reisen Suchen und filtern',
            pwVergessen: 'Passwort vergessen?',
            pwResetNow: 'Passwort jetzt zurücksetzten.',
            NKregister: 'Als Neukunde registrieren',
            JorneyDrawrHead: 'Reise Detail Navigation',
            programm: 'Programm',
            closeBackend: 'Möchten Sie das Programm beenden?',
            mydata: 'Meine Daten',
            enterKaestlWorld:'Enter #kaestlworld',           
            enterBusinessArea:'Enter Business Area',           
            reisen:'REISEN',           
            news:'NEWS',           
            hlKategorien:'Reisen Nach Kategorien',           
            details:'Details',           
            leistungen:'Leistungen',           
            zubuchbareleistungen: 'Zubuchbare Leistungen',
            Rverlauf:'Reiseverlauf',           
            hotel:'Hotel',           
            dashotel:'Das Hotel',           
            hightlights:'Ihre Highlights',           
            hotelklassifizierung:'Hotelklassifizierung', 
            hotelkategorie:'Hotelkategorie', 
            budget:'Budget', 
            zimmerklassifizierung:'Zimmerklassifizierung',           
            zimmerArt:'Art des Zimmers',           
            verpflegung:'Verpflegung',           
            reisedauer:'Reisedauer',           
            länder:'Länder',           
            kategorie:'Kategorie',           
            kataloge:'Katalog',           
            personenzahl:'Personenzahl',           
            reisezeitraum:'Ihr gewünschter Reisezeitraum',           
            reisezeitraumShort:'Reisezeitraum',           
            reisestart:'Reisestart',           
            reiseende:'Reiseende',           
            bis:'bis',           
            Rverfügbar:'Reise verfügbar',
            Ranfrage:'Reise auf Anfrage',
            Rnverfügbar:'Reise nicht verfügbar',
            währungKurz:'€',           
            ab:'ab',           
            zimmeraus:'Zimmerausstattung',           
            hotelaus:'Hotelausstattung',           
            kuraus:'Kuranwendungen',
            gefallen:'Das könnte Ihnen auch gefallen',           
            freitext:'Freitext Suchen',          
            phKategorie:'Bitte eine Kategorie wählen',          
            phlaender:'Bitte wählen Sie ein Land',          
            phessen:'Bitte wählen Sie die Art der Verpflegung',          
            phzimmerKat:'Bitte wählen Sie eine Kategorie',          
            phzimmerArt:'Bitte wählen Sie eine Zimmerart',          
            titleUmkreissuche:'Suche nach Entfernung in km von meinem Firmensitz',          
            entfernung:'Entfernung', 
            newsletterbestellen:'Newsletter abonnieren',
            katalogbestellen:'Katalog bestellen',
            allgemeineanfrage:'Allgemeine Anfrage',
            freitextsuche:'Freitextsuche', 
            katalogezusenden: 'Kataloge zusenden',
            kundenbetreuer:'Besuch Kundenbetreuer', 
            datenschutz:'Mit setzen des Hakens erkläre ich mich einverstanden, dass die von mir erhobenen Daten elektronisch erhoben und gespeichert werden. Diese Einwilligung kann ich jederzeit mit einer Nachricht an uns widerrufen. Weitere Informationen entnehmen Sie der Datenschutzerklärung. Hier zu den Datein.',
            datenschutzbestimmung:'Datenschutzbestimmung',
            hk:'Hauptkatalog',
            kn:'Katalog 2024',
            aws:'Advent / Weihnachten / Silvester',
            vb:'Volle Busse',
            ok:'Oster Katalog',         
            gobackend:'Mein Kundenbereich',
            kundenbereich:'Kundenbereich',
            admin:'Admin',
            adminbereich:'Kunden Bereich',
            minmax:'min / max',
            inkm:'in km',
            "search-dauer": 'Reisedauer in Tagen',
            cancel:'Abbrechen',
            set:'Übernehmen',
            dz:'Doppelzimmer',
            ez:'Einzelzimmer',
            mz:'3 Bettzimmer',
            sons1:'Sonstiges / Verlauf / Zusatzleistungen',
            vereinsfahrt:'Vereinsfahrt',
            printbezug:'evtl. Katalog / Seite',
            reiseanfragen:'Reise anfragen',
            agbunddatens:'Datenschutzbestimmungen akzeptieren',
            ageber:'Das sagen wir als Arbeitgeber',
            anehmer:'Das sagen unsere Mitarbeiter',
            inputfile:'Ihre Datein zu upload',
          

        },
        forms: {
            vorname: 'Vorname',
            nachname: 'Nachname',
            company: 'Firma',
            labeltitel: 'Titel',
            ansprechpartner:"Ansprechpartner",
            altertermin:"Alternativtermin",
            termin:"Termin",
            reiseziel:"Reiseziel",
            labelanrede: 'Anrede',
            anzP: 'Anzahl Personen',
            countries:{
                de:'Deutschland', 
                a:'Österreich', 
                ch:'Schweiz', 
                i:'Italien', 
                n:'Niederlande', 
                be:'Belgien'},
            interressen:{
                Vereine:'Vereinsreise/Betriebsausflug (Erwachsene)',    
                Klassen:'Klassenfahrten/Reisen für Jugendgruppen',    
                Transfer:'Transfer'
            },
            anrede: {
                frau: 'Frau',
                herr: 'Herr',                
                diverse: 'Divers',
            },
            titel: {
                drprof: 'Prof. Dr.',
                prof: 'Prof.',
                dr: 'Dr.',
                ing: 'Ing.',                
                dame: 'Dame',
                sir: 'Sir',

            },
            message:'Ihr Anliegen',
            strasse: 'Straße',
            hausnummer: 'Hausnummer',
            position: 'Position',
            firma: 'Firma',
            birthday: 'Geburtstag',
            land: 'Land',
            plz: 'PLZ',
            ort: 'Ort',
            email: 'E-Mail',
            mobil: 'Mobil',
            fax: 'Telefax',
            tel: 'Telefon',
            password: 'Passwort',
            password_repeat: 'Passwort wiederholen',
            neuespassword: 'Neues Passwort',
            neuespassword_repeat: 'Neues Passwort wiederholen',  
            nachricht: 'Vielen Dank für Ihre Nachricht, wir werden uns in Kürze bei Ihnen melden.',
            errornachricht: 'Die E-Mail konnte leider nicht versendet werden.',
            mailversendet: 'E-Mail versendet',
            fehler: 'Fehler',
            nlangemeldet: 'Sie sind bereits registriert und beim Newsletter angemeldet.'

        },
        registration: {
            success: 'Vielen Dank für Ihre Registrierung, Ihr Account wurde aktiviert und Sie können sich jetzt einloggen.',
            successPw: 'Ihr Passwort wurde aktualisiert, Sie können sich jetzt einloggen.',
            redirect: 'Sie werden in 5 Sekunden automatisch weitergeleitet...',
            activationText: 'Bitte tragen Sie hier den Code ein, der Ihnen per Mail geschickt wurde, um Ihren Account zu aktivieren.',
            resetText: 'Bitte tragen Sie hier den Code ein, der Ihnen per Mail geschickt wurde, um Ihr Passwort zurückzusetzen.',
            activatePlaceholder: 'Aktivierungscode',
            activateButton: 'Account aktivieren',
            resetPwButton: 'Passwort ändern',
            loaderText: 'Bitte haben Sie einen Augenblick Geduld, ihr Aktivierungscode wird geprüft.',
            notificationSuccess: 'Bitte bestätigen Sie die Registrierung durch klick auf den Link in ihrer E-Mail.',
            checkandgo:'Prüfen und Abschicken',
            deleteform: 'Formular löschen',
            emailerror:'Bitte tragen Sie eine gültige E-Mail ein.',
            passerror:'Bitte tragen Sie Ihr Passwort ein.',
            ziperror:'Bitte tragen eine gültige Postleitzahl ein.',
            
        },
        newsletter: {
            success: 'Vielen Dank für Ihre Anmeldung zum Newsletter.',
            redirect: 'Sie werden in 5 Sekunden automatisch weitergeleitet...',
            activationText: 'Bitte tragen Sie hier den Code ein, der Ihnen per Mail geschickt wurde, um sich bei unserem Newsletter anzumelden.',
            activatePlaceholder: 'Aktivierungscode',
            activateButton: 'Newsletter abonnieren',
            loaderText: 'Bitte haben Sie einen Augenblick Geduld, ihr Aktivierungscode wird geprüft.',            
        },
        notice:{
            empty:'Dieses Feld darf nicht leer sein.',
            minlang5:'Dieses Feld mus mindestens 5 Zeichen enthalten.',
            datenschutz:'Sie müssen sich mit den Datenschutzbestimmungen einverstanden erklären.'
        }
    },
    en: {
        global: {
            test: 'Mit Klick zum englischen Wortwolken Raster'
        }
    }
}