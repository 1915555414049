import Com from './class/Com';
import EventEmitter from './class/EventEmitter';

var config;

if (process.env.NODE_ENV !== 'production') {
    config = require('../config/config')
} else {
    config = require('../config/config_prod')
}


class Core extends EventEmitter {

    constructor() {

        super();

        this.id_acc_account = 6;
        this.config = config;
        this.oCom = new Com(this.config.endpoint);
        this.router;

    }

    getPath() {
        return this.router.history.current;
    }

    getPathArgs(descriptor) {
        if (!Array.isArray(this.router.history.current.query.__args)) {
            return {};
        }

        let args = {};
        descriptor = descriptor || {};
        for (let i in this.router.history.current.query.__args) {
            args[descriptor[i] !== undefined ? descriptor[i] : i] = this.router.history.current.query.__args[i]
        }

        return args;
    }

    setRouter(router) {
        this.router = router;

        this.router.afterEach(() => {

            let path = this.router.history.current.path;
            if (path) {
                let q = path.split("?")[0].split("/").reduce((stack, item) => {
                    if (item.trim().length !== 0 && item.trim() !== this.router.history.current.name) {
                        stack.push(item.trim());
                    }
                    return stack;
                }, []);

                if (q.length > 0) {
                    this.router.history.current.query['__args'] = q;
                }
            }

        })

        this.router.beforeEach((to, from, next) => {

            //Prüfen ob vorhanden            
            let route = null;
            for (let i in this.router.options.routes) {
                if (this.router.options.routes[i].name === to.name) {
                    route = this.router.options.routes[i];
                    break;
                }
            }

            if (!route) {
                next({
                    path: '/404'
                });
                return;
            }

            //Prüfen ob Auth
            if (route.protected) {

                this.preload = true;
                this.session.onReady(() => {
                    this.preload = false;

                    if (!this.session.isAuth()) {
                        next({
                            path: '/auth'
                        });
                        return;
                    }

                    next();
                });

                return;
            }

            next()

        });
    }

    navigate(route, forceLoad = false) {
        if (forceLoad || route.path == this.router.history.current.path) {
            this.router.push({
                path: "/plain"
            });
            setTimeout(() => {
                this.router.push(route);
            }, 50);
        } else {
            this.router.push(route);
        }
    }

    backend(cmd, args, callback, delay) {
        return this.oCom.send(cmd, args, callback, delay);
    }

    backendSync(cmd, args, delay) {
        return new Promise((resolve, reject) => {
            this.oCom.send(cmd, args, (err, res) => {
                if (err) {
                    reject(err);
                    return;
                }
                resolve([err, res]);
            }, delay);
        });
    }

    download(cmd, path) {
        this.oCom.download(cmd, path);
    }

    src(path) {
        return this.oCom.endpoint + "static/" + (path ? (path.startsWith("/") ? path.substr(1, path.length) : path) : "");
    }

    hasError(err) {
        if (err) {
            // console.error(err);

            // Notification({
            //     title: "Fehler",
            //     type: "error",
            //     message: err.message
            // });
            this.emit("notification", {
                title: "Fehler",
                type: "error",
                message: err.message
            })

            return true;
        }

        return false;
    }

    notify(title, type, message) {
        this.emit("notification", {
            title,
            type,
            message
        })
        // Notification({
        //     title: title,
        //     type: type,
        //     message: message
        // });
    }

    formatDate(value) {
        try {
            return new Intl.DateTimeFormat("de-DE", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
            }).format(new Date(value))
        } catch (error) {
            return value;
        }
    }

    formatDateTime(value) {
        try {
            return new Intl.DateTimeFormat("de-DE", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
            }).format(new Date(value))
        } catch (error) {
            return value;
        }
    }

    formatPrice(value, short = false) {
        try {
            return new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumIntegerDigits: short ? 0 : 2,
                maximumFractionDigits: short ? 0 : 2
            }).format(value)
        } catch (error) {
            return value + (short ? ',-' : '') + " €"
        }
    }

    formatDuration(value) {
        if (isNaN(parseInt(value))) {
            return 'Unbekannte Dauer';
        }

        return `${parseInt(value)} Tag${parseInt(value) === 1 ? '' : 'e'}`
    }
}

let inst = new Core();

export default inst;