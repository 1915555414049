import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    // State Objekt (z.B. gespeicherte Filter, Suchoptionen, Sortierungen etc.)

    state: {
        coronaState:true,
        JourneyDatefrom:null,
        JourneyDateto:null,
        JourneyCode:null,
        JourneyPanz: [],
        Merkliste: '',
        MenuStore: [],
        JourneyStore: [],
        JourneyStoreSingle: [],
        ContentStore: [],
        CountryStore: [],
        JourneyCountryStore: [],
        JourneyCategoryStore: [],
        JourneyDestinationStore: [],
        BlogStore: [],
        ProjectStore: [],
        SearchStore: {
            search: "",
            category: [],
            country: [],
            dateRange: null,
            date_from: null,
            date_till: null,
            duration: [0, 22],
            freitext: "",
        },
        Settings: {
            journeyoverview: {
                defaultsort: {
                    prop: 'date_from',
                    order: 'ascending'
                }
            }
        },
        WebsiteStore: {
            drawer: false,
            drawerAction: '',
            snackbar: false,
            notification: {},
            bookmarks: {}
        }
    },
    // Methoden um die States zu ändern (NICHT ASYNCHRON!)
    mutations: {

        setSearchStore(state, data) {
            for (const key in data) {
                if (state.SearchStore.hasOwnProperty(key)) {
                    state.SearchStore[key] = data[key];

                }
            }
        },

        setCoronaDialog(state, data) {
            state.coronaState = false;
        },


        setSnack(state, data) {
            state.WebsiteStore.snackbar = true;
            state.WebsiteStore.notification = data;
        },
        openDrawer(state, data) {
            state.WebsiteStore.drawer = true;
            state.WebsiteStore.drawerAction = data;
        },
        setJourneyDatefrom(state, data) {
            state.JourneyDatefrom = data;
        },
        setJourneyDateto(state, data) {
            state.JourneyDateto = data;
        },
        setJourneyName(state, data) {
            state.JourneyNameInForm = data;
        },
        setJourneyPanz(state, data) {
            state.JourneyPanz = data;
        },
        setMerkliste(state, data) {
            state.Merkliste = data;
        },
        setJourneyCode(state, data) {
            state.JourneyCode = data;
        },
        setJourneyFood(state, data) {
            state.JourneyFood = data;
        },
        listMenus(state, data) {
            state.MenuStore = [];
            let buildMenuTree = (data, parent) => {
                let tree = []
                data.forEach(menu => {
                    if (menu.id_web_menu_item_parent == parent) {
                        menu.children = buildMenuTree(data, menu.id_web_menu_item)
                        menu.settings = JSON.parse(menu.settings);
                        tree.push(menu)
                    }
                })
                return tree
            }

            data.forEach(navigation => {
                let subnav = buildMenuTree(navigation.menuItems, 0);
                state.MenuStore.push({
                    id_web_menu: navigation.id_web_menu,
                    name: navigation.name,
                    children: subnav
                })
            })
            core.emit("menu-finished");
        },
        listJourneys(state, data) {
            state.JourneyStore = data;
            state.JourneyStoreSingle = [];

            data.forEach(jrney => {
                try {
                    let cnts = JSON.parse(jrney.extras.find(jex => jex.type == 'Land').text)
                    cnts.forEach(cnt => {
                        if (!state.JourneyCountryStore.includes(cnt)) {
                            state.JourneyCountryStore.push(cnt)
                        }
                    });
                } catch (e) {
                    try {
                        if (!state.JourneyCountryStore.includes(jrney.extras.find(jex => jex.type == 'Land').text) && jrney.extras.find(jex => jex.type == 'Land').text !== "") {
                            state.JourneyCountryStore.push(jrney.extras.find(jex => jex.type == 'Land').text)
                        }
                    } catch (e) {}
                }

                jrney.dates.forEach(date => {
                    let _newJourney = JSON.parse(JSON.stringify(jrney))
                    _newJourney.dates = [date]
                    state.JourneyStoreSingle.push({
                        title: _newJourney.title,
                        date_from: date.date_from,
                        date_till: date.date_till,
                        einleitung: _newJourney.einleitung,
                        reisebeschreibung: _newJourney.reisebeschreibung,
                        duration: date.duration,
                        price: date.price,
                        id_jrn_journey: _newJourney.id_jrn_journey,
                        id_prj_project: _newJourney.id_prj_project,
                        img: _newJourney
                    });
                })
            });
            state.JourneyStoreSingle = state.JourneyStoreSingle.sort((a, b) => a.date_from > b.date_from);
        },
        listContents(state, data) {
            state.ContentStore = data;
            core.emit("content-finished");
        },
        listCategories(state, data) {
            state.JourneyCategoryStore = data;
        },
        listDestinations(state, data) {
            state.JourneyDestinationStore = data;
        },
        listCountries(state, data) {
            data = data.sort((a, b) => a.name > b.name)
            state.JourneyCountryStore = data;
        },
        listBlogs(state, data) {
            data = data.sort((a, b) => a.date_show > b.date_show)
            data.forEach(empl => {
                try {
                    empl.settings = JSON.parse(empl.settings)
                    empl.activeImage = 0
                } catch (e) {}
            })
            state.BlogStore = data;
        },
        listProjects(state, data) {
            state.ProjectStore = data;
        },
        getBookmarks(state, data) {
            data = JSON.parse(data);
            state.WebsiteStore.bookmarks = data;
        },
        dropBookmark(state, data) {
            delete state.WebsiteStore.bookmarks[data];
            localStorage.setItem("bookmarks", JSON.stringify(state.WebsiteStore.bookmarks));
        },
        updateSearchStore(state, data) {
            state.SearchStore = data;
        }
    },
    // Wenn Asynchrone Aktionen erfolgen, dann als action aufrufen, um die Mutation zu triggern
    actions: {
        listMenus(context) {
            core.backend("web.listMenusWeb", {
                id_acc_account: core.id_acc_account,
                page_size: 999
            }, (err, res) => {
                context.commit('listMenus', res.data.rows);
            })
        },
        listJourneys(context) {
            core.backend("jrn.listJourneysWeb", {
                id_acc_account: core.id_acc_account,
                page_size: 999
            }, (err, res) => {
                context.commit('listJourneys', res.data.rows);
            })
        },
        listContents(context) {
            core.backend("web.listContentsWeb", {
                id_acc_account: core.id_acc_account,
                page_size: 999
            }, (err, res) => {
                context.commit('listContents', res.data.rows);
            })
        },
        listCategories(context) {
            core.backend("jrn.listCategoriesWeb", {
                id_acc_account: core.id_acc_account,
                page_size: 999
            }, (err, res) => {
                context.commit('listCategories', res.data.rows);
            })
        },
        listDestinations(context) {
            core.backend("jrn.listDestinationsWeb", {
                id_acc_account: core.id_acc_account,
                page_size: 999
            }, (err, res) => {
                context.commit('listDestinations', res.data.rows);
            })
        },
        listCountries(context) {
            core.backend("jrn.listCountriesWeb", {
                id_acc_account: core.id_acc_account,
                page_size: 999
            }, (err, res) => {
                context.commit('listCountries', res.data.rows);
            })
        },
        listBlogs(context) {
            core.backend("web.listBlogsWeb", {
                id_acc_account: core.id_acc_account,
                page_size: 999
            }, (err, res) => {
                context.commit('listBlogs', res.data.rows);
            })
        },
        listProjects(context) {
            core.backend("prj.listProjectsWeb", {
                id_acc_account: core.id_acc_account,
                page_size: 999
            }, (err, res) => {
                context.commit('listProjects', res.data.rows);
            })
        },
        getBookmarks(context) {
            let _bookmarks = localStorage.getItem("bookmarks");
            if (_bookmarks === null) {
                _bookmarks = "{}";
            }
            context.commit('getBookmarks', _bookmarks);
        }
    },
    // Funktionen die im Zusammenhang mit State etwas returnen, z.B. wenn der State vorm holen noch behandelt werden muss
    getters: {}
})